@import "../../../../styles/mixins";

.side-menu-header {
  @include dependsOnHeaderHeight(height);

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  &__logo {
    outline: none;
    cursor: pointer;
  }
}
