@import "../../../../styles/variables";

.dropzone-text {
  width: 100%;
  color: $color-black--75;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: $font-size--secondary;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;

  &__file-size {
    text-align: center;
    color: $color-black--50;
  }
}
