.button-upload {
  .loading-circular {
    position: absolute;
  }

  input {
    display: none;
  }

  .button-upload-label {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
