.tree-node-label {
  display: flex;
  align-items: center;
  flex-grow: 1;

  &__item + &__item {
    margin-left: 5px;
  }

  .icon-panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;

    &__progress {
      width: 26px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
