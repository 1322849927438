@import "../../styles/variables";

.login {
  .paper {
    width: 350px;
  }

  .app-version {
    text-align: center;
    margin-top: 10px;
    color: $color-black--50;
  }
}
