@import "../../../styles/variables";

.draggable-area-container {
  & > div {
    width: 100%;
  }

  .list__item {
    border-bottom: 1px solid $color-black--50;
  }
  .item-actions {
    display: flex;
  }
}

.empty {
  color: $color-black--50
}
