@import "../../../styles/variables";

.dashboard {
  .widget-content.personal-widget-content {
    .card-loading {
      padding: $container-padding;
      width: 100%;
      box-sizing: border-box;
    }

    .widget-content__empty-placeholder {
      padding: $container-padding;
    }

    padding: 0;
    width: 100%;

    .table {
      display: table;

      table {
        border-collapse: inherit;
        table-layout: fixed;
      }

      thead {
        tr > th:nth-child(1) {
          width: 60px;
        }
      }

      td.MuiTableCell-root,
      td.MuiTableCell-root > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
      }

      .version-status {
        margin-left: 4px;
        color: $color-white;

        &_valid {
          background: $color-success;
        }

        &_invalid {
          background: $color-error;
        }
      }
    }
  }
}
