@import "../../../styles/mixins";

header.header {
  @include sideMenuEffected;
}

.header {
  &__page-title {
    text-align: start;
    flex-grow: 1;
  }
}
