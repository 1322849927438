@import "../../../styles/variables";

.side-menu {
  width: $side-menu-width;

  .list {
    &__item {
      &.Mui-selected {
        background: transparentize($color-primary, .9);
        color: $color-primary;

        svg {
          color: $color-primary;
        }
      }
    }
  }
}
