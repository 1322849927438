@import "../../styles/variables";

.documents {
  &__archived-row {
    background-color: $color-grey-medium;
  }

  .server-table {
    padding: $container-padding;
    box-sizing: border-box;

    table {
      table-layout: fixed;

      thead > tr {
        th:nth-child(1),
        th:nth-child(2) {
          width: 30%;
        }
        th:nth-child(3) {
          width: 10%;
        }
        th:nth-child(4) {
          width: 45px;
        }
        th:nth-child(5) {
          width: 15%;
        }
        th:nth-child(6) {
          width: 100px;
        }
      }

      tbody {
        td,
        td > div,
        .table-cell__clickable {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .version-status {
    margin-left: 4px;
    color: $color-white;

    &_valid {
      background: $color-success;
    }

    &_invalid {
      background: $color-error;
    }
  }
}
