$font-family: Roboto, sans-serif;
$font-family--code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
monospace;

$font-size--basic: 16px;
$font-size--secondary: 14px;
$font-size--large: 20px;

$color-white: #fff;
$color-white--75: darken($color-white, 25%);
$color-white--50: darken($color-white, 50%);
$color-white--25: darken($color-white, 75%);

$color-grey: #808080;
$color-grey-light: transparentize($color-grey, .9);
$color-grey-medium: transparentize($color-grey, .7);

$color-black: #000;
$color-black--75: lighten($color-black, 25%);
$color-black--50: lighten($color-black, 50%);
$color-black--25: lighten($color-black, 75%);

$box-shadow--basic: 0 1px 2px rgba(0,0,0,0.1);

$control-width: 250px;

//--- from material theme ---
$color-primary: #3f51b5;
$color-secondary: #f50057;
$color-error: #f44336;
$color-warning: #f1c40f;
$color-success: #4CAF50;

$color-layout-secondary: #f5f5f5;

$container-padding: 8px 16px;

$side-menu-width: 240px;

$transition-default: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

$header-height-l: 64px;
$header-height-m: 56px;
$header-height-s: 48px;

$icon-button-width: 48px;

$icon-font-size: 1.5rem;
