@import "../../../styles/variables";
@import "../../../styles/mixins";

.table {
  width: 100%;

  &__loading {
    text-align: center;
  }

  &__actions {
    display: flex;
    flex-direction: row;
  }

  .table-cell__clickable {
    text-decoration: underline;
    text-underline-offset: 0.25em;
    cursor: pointer;
  }

  .table-cell__long-string {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 350px; // for cutting long text
  }

  .table-cell__chip + .table-cell__chip {
    margin-left: 5px;
  }

  .table-cell__icon {
    vertical-align: middle;
  }

  .MuiToolbar-root {
    display: flex;
    padding: 0;

    &[role="toolbar"] > div:last-child {
      display: flex;
      flex: 0 1 auto;
    }

    button:hover {
      color: $color-primary;
    }
  }
  .MuiTable-root {
    caption {
      display: none;
    }
  }
  span.MuiTableSortLabel-root.MuiTableSortLabel-active {
    position: absolute;
  }

  .filter-chip + .filter-chip {
    margin-left: 4px;
  }
}

// for filter popover content
div.MuiPopover-root {
  & > div.MuiPaper-root > div {
    padding: $container-padding;
  }

  ul.MuiGridList-root {
    @include columnGrid(2, 0);
    grid-template-columns: minmax(50%, 200px) minmax(50%, 200px);

    & > div {
      margin-top: 0;
    }

    li.MuiGridListTile-root {
      padding: 0;

      & > div {
        margin-top: 0;
      }

      .select {
        min-height: auto;
      }
    }
  }

  .filter-footer {
    margin-top: 8px;
  }
}
