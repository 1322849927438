@import "../../../styles/variables";

.tree {
  .tree-item {
    &__label {
      font-size: $font-size--secondary;
      display: flex;
      align-items: center;
    }

    .MuiTreeItem-iconContainer {
      width: auto;
      margin-right: 0;

      svg {
        font-size: $icon-font-size;
      }
    }
  }
}
