@import "../../../styles/variables";

.edit-template-dialog {
  max-height: 800px;
}

.doc-status {
  color: $color-white;

  &_success {
      color: $color-success;
  }

  &_error {
      color: $color-error;
  }

  &_warn {
      color: $color-warning;
  }
}

.document-status-section {
  margin-top: 10px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
}
