.aging-widget-content {
  align-items: flex-start;

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 250px;
  }

  .aging-graph {
    height: 250px;
    width: 100%;
  }

  .aging-select {
    padding-left: 35px;
  }
}
