@import "variables";

@mixin sideMenuEffected {
  transition: $transition-default;
  &._open {
    width: calc(100% -  #{$side-menu-width});
    margin-left: $side-menu-width;
  }
}

@mixin dependsOnHeaderHeight($property) {
  #{$property}: $header-height-m;

  @media (min-width: 0px) and (orientation: landscape) {
    #{$property}: $header-height-s;
  }

  @media (min-width: 600px) {
    #{$property}: $header-height-l;
  }
}

@mixin columnGrid($columns, $gap) {
  display: grid;
  grid-template-columns: repeat($columns - 1, 100% / $columns) 1fr;
  align-items: baseline;
  grid-column-gap: $gap;
}
