@import "./styles/variables";

body {
  min-height: 100%;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
}

code {
  font-family: $font-family--code;
}

#root {
  width: 100%;
  height: 100%;
  position: absolute;
}
