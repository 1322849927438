@import "../../styles/variables";

.widget {
  &__content {
    overflow: auto;
  }

  &__title {
    background: $color-layout-secondary;
  }
}
