@import "../../../../styles/variables";

.fields-table {
  .table {
    table {
      table-layout: fixed;

      thead > tr {
        th:nth-child(3) {
          width: 200px;
        }
        th:nth-child(4) {
          width: 150px;
        }
      }

      tbody {
        td,
        td > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
