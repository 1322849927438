@import "../../../styles/variables";

.dropzone {
  width: 100%;
  transition: $transition-default;
  min-height: 50px;
  outline: none;
  cursor: pointer;


  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border: 2px dashed $color-black--50;
  border-radius: 5px;
  color: $color-black--50;
  box-sizing: border-box;

  &:hover,
  &._focused {
    color: $color-primary;
    border-color: $color-primary;
  }

  &._filled {
    color: $color-black--50;
    border-color: $color-primary;
  }

  &._error {
    border-color: $color-error;

    .dropzone__help {
      color: $color-error;
    }
  }
}
