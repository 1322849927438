@import "../../../../styles/mixins";

.queue-widget-content {
  min-width: 100%;
  width: auto;

  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;

  .queue-card, .queue-card-loading {
    overflow: hidden;
    width: 450px;

    & + .queue-card,
    & + .queue-card-loading {
      margin-left: 8px;
    }
  }

  .table {
    display: table;

    table {
      border-collapse: inherit;
      table-layout: fixed;

      thead {
        tr > th:nth-child(1) {
          width: 20%;
        }
        tr > th {
          width: 40%;
        }
      }

      span.MuiTableSortLabel-root.MuiTableSortLabel-active {
        position: relative;
      }
    }
    td.MuiTableCell-root {
      padding: 6px 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }

    tr._dragging {
      background: $color-white;
      display: grid;
      grid-template-columns: 20% 40% 40%;
      align-items: center;
      border: 1px solid $color-primary;

      td {
        border: none;
        width: 100%;
        padding: 6px 16px;
        box-sizing: border-box;
      }
    }
  }

  .draggable-area-container {
    &._hidden {
      display: none;
    }
    &__toolbar {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 16px;
    }
  }
}
