.server-table {
  table {
    table-layout: fixed;

    thead > tr {
      th:nth-child(4) {
        width: 30%;
      }
      th:nth-child(5) {
        width: 40px;
      }
    }

    tbody {
      td,
      td > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
