.table {
  table {
    table-layout: fixed;

    thead > tr {
      th:nth-child(5),
      th:nth-child(4) {
        width: 70px;
      }
    }

    tbody {
      td,
      td > div,
      .table-cell__clickable {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
