.dialog {
  div.MuiDialogContent-root {
    padding-top: 8px;
  }

  word-break: break-word;

  div.MuiDialogTitle-root * {
    font-weight: 400;

    .dialog-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      &__entity-name {
        font-weight: 500;
      }
    }
  }
}
