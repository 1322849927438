@import "../../../styles/mixins";

.metadata-fields {
  @include columnGrid(2, 8px);
}

.field-container {
    display: flex;
    flex-direction: column;
}

.field-name {
  margin-top: 30px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.field-add-button {
  display: flex;
}

.document-rules-input-box {
  align-items: baseline;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-column-gap: 8px;
}