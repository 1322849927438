@import "../../../styles/variables";

.chip-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  div.chip-list__item {
    margin: 4px;
    max-width: 250px;
  }

  &__input {
    width: 120px;

    div.MuiInputBase-root {
      max-height: 24px;
      border-radius: 16px;
      width: 120px;
      padding: 0;
    }

    div.MuiInputAdornment-root {
      margin: 0;
    }

    input.MuiInputBase-input {
      font-size: $font-size--secondary;
      padding: 4px 8px;
    }

    button {
      padding: 0;
      margin-right: 4px;
      margin-left: -4px;
    }

    svg.MuiSvgIcon-root {
      font-size: 16px;
    }
  }
}
