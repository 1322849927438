@import "../../styles/variables";

.templates {
  .title {
    display: flex;
    align-items: center;
  }

  &__empty-placeholder {
    color: $color-black--50;
    justify-content: center;
  }
}
