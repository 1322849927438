.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__icon {
    height: 35px;
  }

  h6.logo__name {
    font-size: 15px;
    line-height: 1;
  }
}
