@import "../../../../../styles/variables";

.select {
  width: $control-width;
  min-height: 70px;

  &_full-width {
    width: 100%;
  }

  .MuiFormHelperText-root:first-letter {
    text-transform: capitalize;
  }

  &__subheader {
    height: 36px;
  }
}
