.table-search {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__input {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}
