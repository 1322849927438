@import "../../../styles/variables";

.document-dialog {
  max-height: 800px;

  .form.form-dialog__form {
    & > div:first-child {
      display: flex;
      flex-direction: column;
    }
  }

  .document-dialog-title {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 8px;
    place-items: center start;

    &__label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }
  }

  .tags {
    .chip-list {
      padding: 8px 0;
    }
  }

  &_edit {
    div.MuiDialogContent-root {
      padding: 0;
    }
  }
  button.invalid-tab[aria-selected="false"] {
    color: $color-error;
  }

  .version-status {
    color: $color-white;

    &_valid {
      background: $color-success;
    }

    &_invalid {
      background: $color-error;
    }
  }

  .tab-content {
    padding-top: 16px;
  }

  .tags {
    ._error {
      color: $color-error;

      &._capitalized {
        display: inline-block;
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}
