@import "../../styles/variables";

.dashboard {
  padding-left: 16px;
  padding-right: 16px;

  &__grid {
    padding-top: 8px;
  }

  .widget-content {
    padding: $container-padding;
    box-sizing: border-box;
  }
}
