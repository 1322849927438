.table-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__progress {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
