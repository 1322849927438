@import "../../styles/variables";

.app-logs {
  .server-table {
    padding: $container-padding;
    box-sizing: border-box;

    table {
      table-layout: fixed;

      thead > tr {
        th:nth-child(1),
        th:nth-child(2),
        th:nth-child(6) {
          width: 20%;
        }
        th:nth-child(3),
        th:nth-child(5) {
          width: 10%;
        }
        th:nth-child(4) {
          width: 20%;
        }
      }

      tbody {
        td,
        td > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
        }
      }
    }
  }
}
