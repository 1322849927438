.table-title {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__name + * {
    margin-left: 5px;
  }

  .loading-circular {
    padding: 12px;
  }
}
