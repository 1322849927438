@import "../../../styles/mixins";

.primary-section {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &_start {
    justify-content: flex-start;
  }

  &_center {
    justify-content: center;
  }

  &_end {
    justify-content: flex-end;
  }

  &_vertical-start {
    align-items: flex-start;
  }

  &_vertical-center {
    align-items: center;
  }

  &_vertical-end {
    align-items: flex-end;
  }

  &_with-header {
    box-sizing: border-box;
    @include dependsOnHeaderHeight(padding-top);
  }

  &_full-width > * {
    box-sizing: border-box;
    width: 100%;
  }

  &__loading {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparentize($color-white, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
